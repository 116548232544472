
import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";
import tableCheck from "@/mixins/table-check";
import TableEnableFlag from "@/components/scope/table-enable-flag.vue";
import { emptyToLine } from "@/utils/common";
import { Table } from "@/utils/decorator";
const management = namespace("management");
const base = namespace("base");
@Component({
  components: { TableEnableFlag }
})
@Table("loadListData")
export default class TenantList extends Mixins(tableCheck) {
  @management.Action getTenantList;
  @base.Action getDictionaryList;
  searchForm = {
    tenantName: "",
    contactName: "",
    mobile: ""
  };
  emptyToLine = emptyToLine;
  tenantList: any[] = [];
  appTypeList: any[] = [];
  get searchList() {
    return [
      {
        label: this.$t("v210831.tenant-name-not"),
        type: "input",
        value: "",
        prop: "tenantName"
      },
      {
        label: this.$t("finance.linkman"),
        type: "input",
        value: "",
        prop: "contactName"
      },
      {
        label: this.$t("base.contacts-mobile"),
        type: "input",
        value: "",
        prop: "mobile"
      },
      {
        label: this.$t("v210831.functional-version"),
        type: "select",
        selectList: this.appTypeList,
        value: "",
        prop: "appType"
      }
    ];
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    this.loadListData();
  }
  created() {
    this.init();
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.getTenantList({
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.tenantList = data.data.list;
      return data;
    });
  }
  async init() {
    this.loadListData();
    this.getDictionaryList("app_type").then(data => {
      this.appTypeList = data;
    });
  }
  handleEdit(row) {
    this.$router.push(`/management/tenant-edit/${row.tenantCode}`);
  }
}
